import React from "react";

import Helmet from "react-helmet";

import { css } from "@emotion/react";

import Box from "../../../shared/Box";
import Columns from "../../../shared/Columns";
import Container from "../../../shared/Container";
import Headline from "../../../shared/Headline";
import { useSameOriginHrefAbs } from "../../../shared/LinkComponent";
import Page from "../../../shared/Page";

const LegalTeilnahmebedingungen = React.memo(() => (
    <Page>
        <Helmet>
            <title>
                Teilnahmebedingungen und Datenschutzhinweise | Social Media
                Gewinnspiel
            </title>
            <meta
                name="description"
                content="Herzlich willkommen auf www.hoyer.de | Hoyer Unternehmensgruppe."
            />
            <link
                rel="canonical"
                href={useSameOriginHrefAbs("/teilnahmebedingungen")}
            />
        </Helmet>

        <Container>
            <Columns>
                <Columns.Full>
                    <Box>
                        <Box.Content>
                            <Headline is={1}>
                                Teilnahmebedingungen und Datenschutzhinweise |
                                Social Media Gewinnspiel
                            </Headline>
                            <Headline is={1}>I. Teilnahmebedingungen</Headline>
                            <Headline is={3}>1. Veranstalter</Headline>
                            <p>
                                Unabhängig von der jeweiligen
                                Kommunikationsplattform gilt Wilhelm Hoyer B.V.
                                & Co. KG (nachfolgend „<b>Hoyer</b>“),
                                Rudolf-Diesel-Straße 1, 27374 Visselhövede, als
                                Veranstalter des Gewinnspiels. Das Gewinnspiel
                                steht weder in einer Verbindung zu derjenigen
                                Social Media Plattform, auf der das Gewinnspiel
                                veranstaltet wird, noch zu anderen Social Media
                                Plattformen oder deren Mutterkonzernen. Das
                                Gewinnspiel wird von Vorbenannten nicht
                                gesponsert, unterstützt oder organisiert.
                            </p>

                            <Headline is={3}>2. Teilnahmefrist</Headline>
                            <p>
                                Die Teilnahme am Gewinnspiel ist nur im
                                Aktionszeitraum vom 22.08.2024 bis 26.08.2024,
                                bis 09.30 Uhr möglich.
                            </p>

                            <Headline is={3}>3. Teilnahmeberechtigte</Headline>
                            <p>
                                Teilnahmeberechtigt sind einzelne, natürliche
                                Personen, die zum Zeitpunkt der Teilnahme
                                unbeschränkt geschäftsfähig sind, also das 18.
                                Lebensjahr vollendet haben, und darüber hinaus
                                ihren Wohnsitz in Deutschland haben.
                                Mitarbeiter, die zur Hoyer Unternehmensgruppe
                                gehören, und deren Angehörige sowie alle
                                verbundenen Tankstellenpartner sind von der
                                Gewinnspielteilnahme ausgeschlossen.
                            </p>
                            <p>
                                Pro natürliche Person ist nur eine Teilnahme
                                zulässig. Die gleichzeitige Teilnahme mit einem
                                Hauptaccount sowie mit Nebenaccounts derselben
                                natürlichen Person ist unzulässig. Die Teilnahme
                                mit gefälschten Identitäten oder mit Identitäten
                                Dritter ist nicht erlaubt. Die Teilnahme als
                                juristische Person, Verein, Gesellschaft
                                und/oder Gruppe ist nicht zulässig. Hoyer ist
                                bereits im ersten Fall des Zuwiderhandelns
                                berechtigt, den Teilnehmer auszuschließen.
                                Daneben sind bereits in der Vergangenheit von
                                der Teilnahme ausgeschlossene natürliche
                                Personen nicht teilnahmeberechtigt.
                            </p>

                            <Headline is={3}>4. Teilnahme</Headline>
                            <p>
                                Findet das Gewinnspiel in den Social Media
                                Kanälen der Hoyer (
                                <a href="https://www.facebook.com/hoyer.de">
                                    https://www.facebook.com/hoyer.de
                                </a>
                                ;{" "}
                                <a href="https://www.instagram.com/hoyer.de/">
                                    https://www.instagram.com/hoyer.de/
                                </a>
                                ) statt, erfolgt die Teilnahme durch einfache
                                Handlungen in sozialen Netzwerken. Zur Teilnahme
                                am Gewinnspiel muss die teilnahmeberechtigte
                                Person während des unter Ziffer 2 genannten
                                Gewinnspielzeitraums (1.) dem Social Media Kanal
                                der Hoyer folgen [sog. „
                                <b>follow/Follower werden</b>“], (2.) den
                                Gewinnspiel-Post kommentieren und (3.) den
                                Gewinnspiel-Post „liken“. Mehrere Kommentare
                                einer teilnahmeberechtigten Person unterhalb
                                eines Gewinnspiel-Beitrags werden als eine
                                einzelne Teilnahme gewertet.
                            </p>
                            <p>
                                Die zur Teilnahme erforderlichen Handlungen
                                erfordern üblicherweise, dass die teilnehmende
                                Person im jeweiligen sozialen Netzwerk
                                angemeldet und eingeloggt ist. Einige der zur
                                Teilnahme vorgenommenen Handlungen, wie
                                beispielsweise das Folgen des Social Media
                                Kanals von Hoyer, können nachträglich im
                                jeweiligen Netzwerk wieder deaktiviert werden.
                            </p>
                            <p>
                                Die Teilnahme am Gewinnspiel ist – bis auf
                                etwaige Internetverbindungskosten – kostenlos
                                und auch nicht an sonstige Leistungen des
                                Teilnehmers gebunden.
                            </p>

                            <Headline is={3}>
                                5. Gewinn/Ermittlung der Gewinner
                            </Headline>
                            <p>
                                Art und Umfang des Gewinns ergeben sich
                                unmittelbar aus dem jeweiligen
                                Gewinnspiel-Beitrag. Hoyer ist berechtigt, bei
                                körperlichen Gegenständen geringfügig
                                abweichende Gegenstände (z.B. Farbe) als Gewinn
                                zu übergeben. Der Gewinn kann nicht in bar
                                ausgezahlt werden und ist nicht übertragbar.
                            </p>
                            <p>
                                Die Gewinner werden innerhalb einer Woche nach
                                Ablauf der unter Ziffer 2 genannten
                                Teilnahmefrist unter allen teilnahmeberechtigten
                                Gewinnspielteilnehmern per Zufallsprinzip
                                ermittelt.
                            </p>

                            <Headline is={3}>
                                6. Gewinnerbenachrichtigung, Gewinnannahme und
                                Gewinnübergabe
                            </Headline>
                            <p>
                                Die Gewinner werden unter dem
                                Gewinnspiel-Beitrag in den Kommentaren verlinkt.
                                Zur Gewinnannahme ist es erforderlich, dass der
                                Gewinner innerhalb von drei Tagen auf die
                                Verlinkung reagiert. Reagiert der ausgeloste
                                Gewinner nicht innerhalb von drei Tagen,
                                erlischt sein Gewinnanspruch. Hoyer ist in
                                diesem Fall berechtigt, eine Ersatzauslosung
                                durchzuführen. Für die Zustellung des Gewinns
                                werden die Gewinner gebeten, ihre Kontaktdaten
                                (Vor- und Nachname sowie Postanschrift) an die
                                E-Mail-Adresse der Hoyer, Team Social Media,
                                unter socialmedia(at)hoyer.de zu senden, oder
                                alternativ per Direktnachricht an das jeweilige
                                Profil zu schreiben.
                            </p>

                            <Headline is={3}>
                                7. Vorzeitige Spielbeendigung
                            </Headline>
                            <p>
                                Technische Probleme bei der Teilnahme am
                                Gewinnspiel können nie ausgeschlossen werden.
                                Für eine vorübergehende Unerreichbarkeit der
                                Plattformen Instagram und Facebook oder für
                                Datenverluste und daraus entstehende Schäden
                                übernimmt die Hoyer keine Haftung. Hoyer behält
                                sich das Recht vor, das Gewinnspiel jederzeit
                                vorzeitig zu beenden oder in seinem Verlauf
                                abzuändern, wenn es aus technischen oder
                                rechtlichen Gründen nicht möglich ist, eine
                                ordnungsgemäße Durchführung des Gewinnspiels zu
                                gewährleisten.
                            </p>

                            <Headline is={3}>
                                8. Rechtsweg, Rechtswahl und Sonstiges
                            </Headline>
                            <p>Der Rechtsweg ist ausgeschlossen.</p>
                            <p>
                                Sollten einzelne Bestimmungen dieser
                                Teilnahmebestimmungen unwirksam sein oder
                                während/nach dem Gewinnspiel unwirksam werden,
                                bleibt davon die Wirksamkeit der übrigen
                                Klauseln unberührt.
                            </p>
                            <p>
                                Es gilt das Recht der Bundesrepublik
                                Deutschland; die Geltung des UN-Kaufrechts und
                                der kollisionsrechtlichen Vorschriften ist
                                ausgeschlossen. Diese Rechtswahl lässt gegenüber
                                Verbrauchern, diejenigen Bestimmungen unberührt,
                                von denen nach dem Recht des Landes, in welchem
                                der Teilnehmer seinen gewöhnlichen Aufenthalt
                                hat, nicht durch Vereinbarung abgewichen werden
                                darf.
                            </p>

                            <Headline is={1}>II. Datenschutzerklärung</Headline>
                            <Headline is={3}>1. Geltungsbereich</Headline>
                            <p>
                                Diese Datenschutzerklärung gilt für sämtliche
                                auf den Social Media Kanälen des
                                Verantwortlichen veranstalteten Gewinnspiele.
                            </p>
                            <Headline is={3}>
                                2. Name und Anschrift des Verantwortlichen
                            </Headline>
                            <p>
                                Der Verantwortliche im Sinne der
                                Datenschutzgrundverordnung (DSGVO) und anderer
                                nationaler Datenschutzgesetze der
                                Mitgliedsstaaten sowie sonstiger
                                datenschutzrechtlicher Bestimmungen ist die:
                            </p>
                            <p>
                                Wilhelm Hoyer B.V. & Co. KG <br />
                                Rudolf-Diesel-Straße 1 <br />
                                27374 Visselhövede <br />
                                Deutschland <br />
                                Telefonnummer: +49 (0) 4262 – 7 97 <br />
                                E-Mail:{" "}
                                <a href="mailto:info@hoyer.de">info@hoyer.de</a>
                            </p>
                            <p>
                                Unseren Datenschutzbeauftragten erreichen Sie
                                unter der oben genannten Postadresse, mit dem
                                Zusatz „An den Datenschutzbeauftragten“ oder
                                unter der E-Mail-Adresse:
                                datenschutz(at)hoyer.de. Unser
                                Datenschutzbeauftragter hört sich auch gerne
                                Ihre Fragen, Anregung oder Kritik zum
                                Datenschutz an.
                            </p>

                            <Headline is={3}>
                                3. Umfang der Verarbeitung personenbezogener
                                Daten
                            </Headline>
                            <p>
                                (1) Wir verarbeiten die folgenden Kategorien von
                                personenbezogenen Daten:
                            </p>
                            <ol
                                type="a"
                                css={css`
                                    margin-left: 40px;
                                `}
                            >
                                <li>Name</li>
                                <li>Account-Name</li>
                                <li>Adresse</li>
                                <li>E-Mail-Adresse</li>
                            </ol>
                            <br />

                            <p>
                                (2) Die personenbezogenen Daten werden im Rahmen
                                der Erfüllung des Gewinnspielvertrags
                                verarbeitet. Eine Verarbeitung erfolgt
                                insbesondere zur Ermittlung des/der Gewinner
                                sowie zum Zweck der Übermittlung/Übersendung des
                                Gewinns.
                            </p>

                            <Headline is={3}>
                                4. Rechtsgrundlage der Verarbeitung
                                personenbezogener Daten
                            </Headline>
                            <p>
                                Die Verarbeitung der personenbezogenen Daten
                                erfolgt auf Grundlage des Artikel 6 Absatz 1
                                Unterabsatz 1 Buchstabe b) DSGVO, der die
                                Verarbeitung zur Erfüllung eines Vertrages
                                gestattet.
                            </p>

                            <Headline is={3}>
                                5. Zweck der Datenverarbeitung
                            </Headline>
                            <p>
                                Die personenbezogenen Daten werden zum Zwecke
                                der Erfüllung des Gewinnspielvertrags. Die
                                Verarbeitung findet insbesondere zum Zweck der
                                Gewinnerermittlung und der
                                Gewinnerbenachrichtigung (Kontaktaufnahme) sowie
                                der Gewinnübermittlung/Übersendung statt.
                            </p>
                        </Box.Content>
                    </Box>
                </Columns.Full>
            </Columns>
        </Container>
    </Page>
));
LegalTeilnahmebedingungen.displayName = "LegalTeilnahmebedingungen";

export default LegalTeilnahmebedingungen;
